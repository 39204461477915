<template>
  <div>
    <v-container fluid class="py-6">
      <div class="d-sm-flex justify-between">
        <div class="d-flex ms-auto">
          <v-menu transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default btn-outline-default
                  py-2
                  px-6
                  me-2
                "
                color="transparent"
                small
              >
                {{ filter_name }}
                <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0">
              <v-list-item
                class="list-item-hover-active py-2"
                v-for="item in history_type_enum_str"
                :key="item.value"
                @click="history_type = item.value"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default btn-outline-default
              py-2
              px-6
              me-2
            "
            color="transparent"
            small
          >
            <v-icon>ni ni-archive-2 me-1</v-icon>
            Export CSV
          </v-btn>
        </div>
      </div>

      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <table-orders
              :history_type="history_type"
              ref="table_orders"
            ></table-orders>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { history_type_enum, history_type_enum_str } from "@/definitions.js";
import CommonUtility from "@/util/CommonUtility.js";
import TableOrders from "@/components/CRM/TableOrders";

export default {
  name: "Shop-Orders-All",
  components: {
    TableOrders,
  },
  data() {
    return {
      history_type: history_type_enum.Unknown,
      history_type_enum_str: history_type_enum_str,
    };
  },
  computed: {
    filter_name: function () {
      if (this.history_type != null) {
        return CommonUtility.getText(
          this.history_type,
          this.history_type_enum_str
        );
      }
      return "filter";
    },
  },
};
</script>
